import React from "react";

const GetStartedPage = () => {
    return <div>
        <iframe
            title="Business Questionnaire"
            class="airtable-embed"
            src="https://airtable.com/embed/shr9WQgOF9PjmDWog?backgroundColor=red"
            frameborder="0"
            onmousewheel=""
            width="100%"
            height="3000px"
            style={{background: "transparent", border: "1px solid #ccc"}}>

        </iframe>
    </div>
};

export default GetStartedPage;
